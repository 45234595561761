import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCarousel,
  InputGroup,
  InputGroupText,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  UncontrolledAccordion,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

//Simple bar
import SimpleBar from "simplebar-react";

import { Swiper, SwiperSlide } from "swiper/react";
import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { DetailImgModal, DetailInfoModal, DetailUseAdviceModal } from "../../Components/Common/ManagerModal";
import { API, BACKEND_HOST } from "../../api";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
import styled from "styled-components";
import { useUserStore } from "../../store/store";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

const ProductReview = (props) => {
  const [review, setReview] = React.useState({});
  React.useEffect(() => {
    setReview(props.review);
  }, []);

  window.onpopstate = function (event) {
    // 뒤로 가기 버튼 클릭 시 새로고침하고자 하는 동작 수행
    window.location.reload();
  };

  return (
    <React.Fragment>
      <li className="py-2">
        <div className="border border-dashed rounded p-3">
          <div className="d-flex align-items-start mb-3">
            <div className="hstack gap-3">
              <div className="badge rounded-pill bg-success mb-0">
                <i className="mdi mdi-star"></i> {review.rating}
              </div>
              <div className="vr"></div>
              <div className="flex-grow-1">
                <p className="text-muted mb-0">{review.content}</p>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-end">
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-0">{`${review.user}님`}</h5>
            </div>

            <div className="flex-shrink-0">
              <p className="text-muted fs-13 mb-0">{`${review.date.year}-${review.date.month}-${review.date.day} ${review.date.hour}:${review.date.minute}`}</p>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

function DetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const membershipPrice = location.state?.membershipPrice;

  const [product, setProduct] = useState({});

  const [quantity, setQuantity] = useState(1);

  const [detailImageEditModal, setDetailImageEditModal] = useState(false);
  const [detailInfoModal, setDetailInfoModal] = useState(false);
  const [detailUseAdviceModal, setDetailUseAdviceModal] = useState(false);

  const [optionProducts, setOptionProducts] = useState([]);
  const [reservationOptions, setReservationOptions] = useState([]);

  const [optionQuantity, setOptionQuantity] = useState([]);
  const [modal, setModal] = useState(false);
  const [user] = useUserStore((state) => [state.user]);

  const toggleModal = () => setModal(!modal);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [optionOpen, setOptionOpen] = useState("1");
  const optionToggle = (id) => {
    if (optionOpen === id) {
      setOptionOpen();
    } else {
      setOptionOpen(id);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log("props", location.state);
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await API.get(`/categorys/product/${props.id}/?type=${props.type}&date=${props.selectDate}`);
        const productData = productResponse.data;
        console.log("detail", productData);

        if (props.type === 0) {
          const updatedProduct = membershipPrice !== 0 && membershipPrice !== undefined ? { ...productData, price: membershipPrice } : productData;

          setProduct(updatedProduct);

          if (props.type === 0) {
            const optionsResponse = await API.get(`/package/items/${props.id}`);
            const optionsData = optionsResponse.data;
            console.log("optionProducts", optionsData.options);
            setOptionProducts(optionsData.options);
          }
        } else {
          if (user && user.membership !== "" && productData.membership_price !== 0 && productData.membership_price <= productData.price) {
            setProduct({ ...productData, price: productData.membership_price });
          } else {
            setProduct(productData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [props.id, props.type, props.selectDate]);

  const handleReservationOption = (checkValue, spotId, optionId, name, price, type, quantity) => {
    // console.log("handleReservationOption", checkValue, spotId, optionId);

    let _reservOptions = [...reservationOptions];
    const _optionIdx = _reservOptions.findIndex((value) => {
      return value.spotId === spotId && value.optionId === optionId;
    });
    if (_optionIdx !== -1) {
      if (!checkValue) {
        // remove
        _reservOptions.splice(_optionIdx, 1);
      }
    } else if (checkValue) {
      // add
      _reservOptions = [
        ...reservationOptions,
        {
          spotId: spotId,
          name: name,
          optionId: optionId,
          price: price,
          type: type,
          quantity: quantity,
        },
      ];
    }

    setReservationOptions(_reservOptions);
  };

  const handleReservationOptionQuantity = reservationOptions.flatMap((item) => {
    const clonedItems = [];
    for (let i = 0; i < item.quantity; i++) {
      clonedItems.push({ ...item });
    }
    return clonedItems;
  });

  // useEffect(() => {
  //   console.log("handleReservationOption", reservationOptions);
  // }, [reservationOptions]);

  // useEffect(() => {
  //   console.log("optionProducts", optionProducts)
  //   if (optionProducts.find((value) => value.type === "deposit")) {
  //     const depositOption = optionProducts.find((value) => value.type === "deposit")
  //     let _reservOptions = []
  //     for (let i = 0; i < props.spotId.length; i++) {
  //       let spotItem = props.spotId[i]
  //       console.log("spotItem", spotItem)
  //       _reservOptions.push({
  //         "spotId": spotItem.id,
  //         "name": depositOption.name,
  //         "optionId": depositOption.id,
  //         "price": depositOption.price
  //       })
  //     }
  //     setReservationOptions(_reservOptions)
  //   }
  // }, [optionProducts])

  return (
    <div>
      <Container fluid style={{ minHeight: "100vh", paddingBottom: "300px" }}>
        <Row>
          <Col lg={12}>
            {!!props.permission && props.permission.permission > 0 ? (
              <Button className="btn btn-soft-warning mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailImageEditModal(!detailImageEditModal)}>
                상세이미지 수정
              </Button>
            ) : null}
            <Card>
              <CardBody className="px-0">
                <Row className="gx-lg-5">
                  <Col xl={12} md={12} className="mx-auto">
                    <div className="product-img-slider sticky-side-div">{product ? <UncontrolledCarousel interval={4000} items={product.images ? product.images : []} /> : null}</div>
                  </Col>

                  <Col xl={12}>
                    <div className="mt-xl-2 mt-5">
                      <div className="d-flex">
                        <div className="flex-grow-1 mt-3">
                          <h3 style={{ fontFamily: "Gmarket-B" }}>{product.name}</h3>
                        </div>
                      </div>

                      {props.type === 0 ? (
                        <div className="mt-4">
                          <div>
                            {props.spotId.map((spotItem, idx) => {
                              return (
                                <div key={spotItem.id}>
                                  <div className="mx-2 mt-4 mb-4" style={{ marginBottom: "10px", fontFamily: "Gmarket-M" }}>
                                    자리 번호 <span style={{ color: "#F6475F" }}>{spotItem.name}</span> 번
                                  </div>

                                  {optionProducts && optionProducts.length ? (
                                    <UncontrolledAccordion open={optionOpen} toggle={optionToggle} defaultOpen="1">
                                      {product.option_required && optionProducts.find((value) => value.type === "goods") ? (
                                        <AccordionItem>
                                          <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                            필수 옵션*
                                          </AccordionHeader>
                                          <AccordionBody accordionId="1">
                                            <div className="">
                                              {optionProducts
                                                .filter((item) => item.type === "goods")
                                                .map((optionItem, idx) => {
                                                  if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                  return (
                                                    <div
                                                      key={optionItem.id}
                                                      className="form-check my-2 mx-2"
                                                      dir="ltr"
                                                      style={{ borderBottom: "1px solid #ededed", paddingBottom: "10px", paddingTop: "10px" }}
                                                    >
                                                      <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`${spotItem.id}-${optionItem.id}`}
                                                        checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                        // disabled={optionItem.type === "soldout"}
                                                        onChange={(e) => {
                                                          handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1);
                                                        }}
                                                      />
                                                      <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                        {optionItem.name} <br />+{optionItem.price}원
                                                      </Label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </AccordionBody>
                                        </AccordionItem>
                                      ) : null}

                                      {product.option_required && optionProducts.find((value) => value.type !== "goods") ? (
                                        <AccordionItem>
                                          <AccordionHeader targetId="2" style={{ fontFamily: "Gmarket-M" }}>
                                            선택 옵션
                                          </AccordionHeader>
                                          <AccordionBody accordionId="2">
                                            <div className="">
                                              {optionProducts
                                                .filter((item) => item.type !== "goods")
                                                .map((optionItem, idx) => {
                                                  if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                  return (
                                                    <div key={optionItem.id} className="form-check my-2 mx-2" dir="ltr">
                                                      <Input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`${spotItem.id}-${optionItem.id}`}
                                                        checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                        disabled={optionItem.type === "soldout"}
                                                        onChange={(e) => {
                                                          handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1);
                                                        }}
                                                      />
                                                      <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                        {optionItem.name} <br />+{optionItem.price}원 &nbsp;{optionItem.type === "soldout" ? <span className="text-danger fw-bold">품절</span> : ""}
                                                      </Label>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </AccordionBody>
                                        </AccordionItem>
                                      ) : null}

                                      {!product.option_required ? (
                                        <AccordionItem>
                                          <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                            선택 옵션
                                          </AccordionHeader>
                                          <AccordionBody accordionId="1">
                                            <div className="">
                                              {optionProducts.map((optionItem, idx) => {
                                                if (optionItem.type === "deposit") return <div key={optionItem.id}></div>;
                                                return (
                                                  <div
                                                    key={optionItem.id}
                                                    className="form-check my-2 mx-2"
                                                    dir="ltr"
                                                    style={{ borderBottom: "1px solid #ededed", paddingBottom: "10px", paddingTop: "10px" }}
                                                  >
                                                    <Input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id={`${spotItem.id}-${optionItem.id}`}
                                                      checked={reservationOptions.find((value) => value.spotId === spotItem.id && value.optionId === optionItem.id)}
                                                      disabled={optionItem.type === "soldout"}
                                                      onChange={(e) => {
                                                        handleReservationOption(e.currentTarget.checked, spotItem.id, optionItem.id, optionItem.name, optionItem.price, optionItem.type, 1);
                                                      }}
                                                    />
                                                    <Label for={`${spotItem.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                      {optionItem.name} <br />+{optionItem.price}원 &nbsp;{optionItem.type === "soldout" ? <span className="text-danger fw-bold">품절</span> : ""}
                                                    </Label>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </AccordionBody>
                                        </AccordionItem>
                                      ) : null}
                                    </UncontrolledAccordion>
                                  ) : null}
                                </div>
                              );
                            })}
                            {/* <div>{JSON.stringify(reservationOptions)}</div> */}
                          </div>
                        </div>
                      ) : null}

                      <div className="product-content mt-5">
                        {/* <h5 className="fs-14 mb-3">Information :</h5> */}
                        <Nav tabs className="nav-tabs-custom nav-primary">
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1" })} onClick={() => toggleCustom("1")}>
                              상세정보
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")}>
                              이용안내
                            </NavLink>
                          </NavItem>
                          {optionProducts.find((item) => item.type === "deposit") ? (
                            <NavItem>
                              <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3" })} onClick={() => toggleCustom("3")}>
                                청소보증금 안내
                              </NavLink>
                            </NavItem>
                          ) : null}
                        </Nav>
                        {/* 이거 없애면됨 */}
                        <TabContent activeTab={customActiveTab} className="pt-4" id="nav-tabContent" style={{ padding: "0px 10px" }}>
                          <TabPane id="nav-detail" tabId="1">
                            {!!props.permission && props.permission.permission > 0 ? (
                              <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailInfoModal(!detailInfoModal)}>
                                상세정보 수정
                              </Button>
                            ) : null}
                            <div dangerouslySetInnerHTML={{ __html: product.descript }} />
                          </TabPane>
                          <TabPane id="nav-speci" tabId="2">
                            {!!props.permission && props.permission.permission > 0 ? (
                              <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0" style={{ zIndex: "2" }} onClick={() => setDetailUseAdviceModal(!detailUseAdviceModal)}>
                                이용안내 수정
                              </Button>
                            ) : null}
                            <div dangerouslySetInnerHTML={{ __html: product["using_info"] }} />
                          </TabPane>
                          <TabPane id="nav-warranty" tabId="3">
                            <div>
                              <h4>청소보증금이란?</h4>
                              <div style={{ height: "22px" }} />
                              <p style={{ color: "#FF5A5F" }}>청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 자동결제됩니다.</p>
                              <p>시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.</p>
                              <p style={{ color: "#FF5A5F" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</p>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row
          className="position-fixed d-flex flex-row justify-content-center"
          style={{
            maxWidth: "640px",
            bottom: "0px",
            width: "100%",
            zIndex: 999,
            backgroundColor: "#fff",
            paddingBottom: "25px",
            paddingTop: "25px",
            alignItems: "center",
            borderTop: "1px solid #ededed",
          }}
        >
          {props.type !== 0 ? (
            <div style={{ width: "25%", display: "inline-block", paddingInline: "8px", marginRight: "25px" }}>
              <QuantityButtonInput
                size={28}
                quantity={quantity}
                onChange={(value) => {
                  if (product?.remaining_stock && value > product.remaining_stock) {
                    alert("구매 가능 개수를 초과하였습니다.");
                    return;
                  }
                  setQuantity(value);
                }}
              />
            </div>
          ) : null}

          {reservationOptions.length > 0 ? (
            <div style={{ maxHeight: "200px", overflowY: "scroll", width: "100%" }}>
              {reservationOptions.map((options, index) => (
                <div
                  key={"reservationOptions" + index}
                  style={{
                    width: "95%",
                    padding: "20px 20px",
                    margin: "8px auto",
                    borderRadius: "10px",
                    borderBottom: "1px solid #ededed",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  {props.spotId.map((spotItem, idx) =>
                    spotItem.id === options.spotId ? (
                      <span key={idx} style={{ maxWidth: "50%" }}>
                        자리 {spotItem.name} / {options.name}
                      </span>
                    ) : <React.Fragment key={idx}></React.Fragment>
                  )}

                  <QuantityButtonInput
                    quantity={reservationOptions[index].quantity}
                    detailPage={true}
                    onChange={(value) => {
                      const _reservationOptions = [...reservationOptions];
                      if (_reservationOptions[index].type === "extra") {
                        if (value > 2) {
                          alert("최대 2개까지 선택 가능합니다.");
                          return;
                        }
                      }
                      _reservationOptions[index].quantity = value;
                      setReservationOptions(_reservationOptions);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : null}
          <Button
            // color="danger"
            className="btn fw-bold fs-16"
            style={{
              width: props.type !== 0 ? "60%" : "95%",
              backgroundColor: "#F6475F",
              border: "none",
              padding: "14px 0px",
              borderRadius: "12px",
              fontFamily: "Gmarket-M",
            }}
            size="lg"
            onClick={() => {
              if (props.type === 0) {
                // 패키지 상품
                if (product.option_required) {
                  if (!reservationOptions.find((value) => value.type === "goods")) {
                    toggleModal();
                    return;
                  }
                }
                navigate(`/order`, {
                  state: {
                    type: props.type,
                    product: product,
                    selectDate: props.selectDate,
                    spotId: props.spotId,
                    options: handleReservationOptionQuantity,
                    quantity: props.type !== 0 ? quantity : 1, // 나중에 티켓 수량 선택 시 이용할 필드
                  },
                });
              } else {
                // 티켓 상품
                navigate(`/order-ticket-multi`, {
                  state: {
                    products: [
                      {
                        ...product,
                        quantity: quantity,
                      },
                    ],
                  },
                });
              }
            }}
          >
            구매하기
          </Button>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          <span style={{ fontFamily: "Gmarket-M" }}>필수 옵션을 선택해 주세요.</span>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggleModal} style={{ backgroundColor: "#dd4056", border: "none" }}>
            확인
          </Button>
        </ModalFooter>
      </Modal>

      <DetailImgModal setVisible={setDetailImageEditModal} visible={detailImageEditModal} itemId={product.id} type={props.type === 0 ? "package" : "ticket"} navigate={navigate} />
      <DetailInfoModal setVisible={setDetailInfoModal} visible={detailInfoModal} itemId={product.id} type={props.type === 0 ? "package" : "ticket"} navigate={navigate} />
      <DetailUseAdviceModal setVisible={setDetailUseAdviceModal} visible={detailUseAdviceModal} itemId={product.id} type={props.type === 0 ? "package" : "ticket"} navigate={navigate} />
    </div>
  );
}

export default DetailPage;
