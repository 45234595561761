import React, { } from "react";
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";

// {
//     "altText": " ",
//     "caption": " ",
//     "key": 886,
//     "src": "https://media.amazingticket.site/carousel/%E1%84%8B%E1%85%A5%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8C%E1%85%B5%E1%86%BC%E1%84%87%E1%85%A1%E1%84%87%E1%85%A6%E1%84%8F%E1%85%B2-%E1%84%86%E1%85%A6%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%87%E1%85%A2%E1%84%82%E1%85%A5.jpg",
//     "display_level": 0,
//     "link_url": "https://amazingcamp.site/32",
//     "title": null,
//     "description": null,
//     "data": null
// },

export default function AmCarousel({
    interval = 5000,
    items = [],
    style = {}
}) {
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const onExiting = () => {
        setAnimating(true);
    }

    const onExited = () => {
        setAnimating(false);
    }

    return (<>
        {items.length === 1 ? <div style={{ ...style, position: "relative" }}>
            {items[0].link_url ?
                <a href={items[0].link_url ? items[0].link_url : "#"} target={items[0].link_url ? "_blank" : "_self"} rel="noreferrer">
                    <img src={items[0].src} alt={items[0].altText} style={styles.carouselImage} />
                </a>
                : <img src={items[0].src} alt={items[0].altText} style={styles.carouselImage} />
            }
            {items[0].caption ? <CarouselCaption captionText={items[0].caption} captionHeader={items[0].caption} /> : null}
        </div> : <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={interval}
            style={style}
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {items.map((item) => {
                return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                    >
                        {item.link_url ?
                            <a href={item.link_url ? item.link_url : "#"} target={item.link_url ? "_blank" : "_self"} rel="noreferrer">
                                <img src={item.src} alt={item.altText} style={styles.carouselImage} />
                            </a>
                            : <img src={item.src} alt={item.altText} style={styles.carouselImage} />
                        }
                        {item.caption ? <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> : null}
                    </CarouselItem>
                );
            })}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>}
    </>);
}

const styles = {
    carouselImage: {
        width: "100%",
        height: "auto",
        objectFit: "cover"
    }
};