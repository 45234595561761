import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCarousel,
  InputGroup,
  InputGroupText,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  UncontrolledAccordion,
} from "reactstrap";
import { API, BACKEND_HOST } from "../../api";
import Navbar from "../../Components/Common/navbar";
import { getAccessToken } from "../../api/auth";

const AddOptionsDetail = () => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [optionProducts, setOptionProducts] = useState([]);
  const [reservationOptions, setReservationOptions] = useState([]);

  const [reservationData, setReservationData] = useState(null);

  const [optionOpen, setOptionOpen] = useState("1");
  const optionToggle = (id) => {
    if (optionOpen === id) {
      setOptionOpen();
    } else {
      setOptionOpen(id);
    }
  };

  React.useEffect(() => {
    if (id) {
      const accessToken = getAccessToken();

      // 첫 번째 요청
      API.get(`/package/get-reservation/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const data = response.data;
          console.log(`package/reservations/${id}/`, data);

          if (!data.package_id) {
            alert("예약 정보 로드 실패..");
          }

          setReservationData(data);

          const package_id = data.package_id;

          // 두 번째 요청
          return API.get(`/package/items/${package_id}/`);
        })
        .then((response) => {
          const data = response.data;
          console.log(`package/items`, data);
          setOptionProducts(data.options);
        })
        .catch((error) => console.log(error));
    }

    setOptionProducts([]);
  }, [id]);

  const handleReservationOption = (checkValue, reservId, optionId, name, price) => {
    // console.log("handleReservationOption", checkValue, reservId, optionId)
    let _reservOptions = [...reservationOptions];
    const _optionIdx = _reservOptions.findIndex((value) => {
      return value.reservId === reservId && value.optionId === optionId;
    });
    if (_optionIdx !== -1) {
      if (!checkValue) {
        // remove
        _reservOptions.splice(_optionIdx, 1);
      }
    } else if (checkValue) {
      // add
      _reservOptions = [
        ...reservationOptions,
        {
          reservId: reservId,
          name: name,
          optionId: optionId,
          price: price,
        },
      ];
    }

    setReservationOptions(_reservOptions);
  };

  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            <Row className="px-2">
              <Col lg={12}>
                <Card>
                  {reservationData ? (
                    <CardBody className="px-0">
                      <Row className="gx-lg-5">
                        <Col xl={12}>
                          <div className="mt-xl-2 mt-5">
                            <div className="d-flex flex-column">
                              <div className="flex-grow-1">
                                <h4 style={{ fontFamily: "Gmarket-M" }}>{reservationData["name"]}</h4>
                              </div>
                              <div className="mt-2">
                                <div className="mx-2">자리 {reservationData["spot"]}</div>
                                <div className="mx-2">예약시작일 20{reservationData["reservationDate"]}</div>
                              </div>
                            </div>

                            {reservationData.options && reservationData.options.length ? (
                              <div className="mt-5">
                                <div className="mb-2">
                                  <h5>기존 옵션</h5>
                                </div>
                                <div className="d-flex flex-row justify-content-start gap-4 flex-wrap">
                                  {reservationData.options.map((optionItem, idx) => {
                                    return (
                                      <div key={idx} className="form-check my-2" dir="ltr">
                                        <Input type="checkbox" className="form-check-input" id={`${reservationData.id}-${idx}-old`} checked disabled />
                                        <Label for={`${reservationData.id}-${idx}-old`} style={{ fontSize: "12px" }}>
                                          {optionItem.name} <br />+{optionItem.price}원
                                        </Label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : null}

                            <div className="mt-5">
                              <UncontrolledAccordion open={optionOpen} toggle={optionToggle} defaultOpen="1">
                                <AccordionItem>
                                  <AccordionHeader targetId="1" style={{ fontFamily: "Gmarket-M" }}>
                                    추가 옵션
                                  </AccordionHeader>

                                  <AccordionBody accordionId="1">
                                    {optionProducts.map((optionItem, idx) => {
                                      return (
                                        <div key={idx} className="form-check my-2" dir="ltr">
                                          <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`${reservationData.id}-${optionItem.id}`}
                                            onChange={(e) => {
                                              handleReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price);
                                            }}
                                          />
                                          <Label for={`${reservationData.id}-${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                            {optionItem.name} <br />+{optionItem.price}원
                                          </Label>
                                        </div>
                                      );
                                    })}
                                  </AccordionBody>
                                </AccordionItem>
                              </UncontrolledAccordion>
                              {/* <div className="d-flex flex-row justify-content-start gap-4 flex-wrap">
                                {optionProducts.map((optionItem, idx) => {
                                  return (
                                    <div key={idx} className="form-check my-2" dir="ltr">
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`${reservationData.id}-${optionItem.id}`}
                                        onChange={(e) => {
                                          handleReservationOption(e.currentTarget.checked, reservationData.id, optionItem.id, optionItem.name, optionItem.price);
                                        }}
                                      />
                                      <Label for={`${reservationData.id}-${optionItem.id}`} style={{ fontSize: "12px" }}>
                                        {optionItem.name} <br />+{optionItem.price}원
                                      </Label>
                                    </div>
                                  );
                                })}
                              </div> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  ) : (
                    <div>loading...</div>
                  )}
                </Card>
              </Col>
            </Row>
            {/* 
            <div style={{ position: "fixed", bottom: "0", left: "0", right: "0", zIndex: "4" }}>
              <Button
                color="danger"
                className="btn fw-bold fs-15"
                style={{ width: "100%" }}
                size="lg"
                onClick={() => {
                  navigate(`/add-options-order`, {
                    state: {
                      reservationName: reservationData["name"],
                      spotName: reservationData["spot"],
                      reservDate: reservationData["reservationDate"],
                      reservId: reservationData.id,
                      options: reservationOptions,
                    },
                  });
                }}
                disabled={!reservationOptions.length}
              >
                추가옵션 구매하기
              </Button>
            </div> */}

            <div style={{ position: "fixed", bottom: "0", left: "50%", right: "0", zIndex: "4", maxWidth: "640px", width: "100%", transform: "translateX(-50%)" }}>
              <button
                type="button"
                className="btn ms-auto nexttab"
                style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                onClick={() => {
                  navigate(`/add-options-order`, {
                    state: {
                      reservationName: reservationData["name"],
                      spotName: reservationData["spot"],
                      reservDate: reservationData["reservationDate"],
                      reservId: reservationData.id,
                      options: reservationOptions,
                    },
                  });
                }}
                disabled={!reservationOptions.length}
              >
                결제하기
                <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
              </button>
            </div>
          </Container>
        </div>

        {/* <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" style={{ bottom: "10%" }} id="back-to-top">
          <i className="ri-arrow-up-line"></i>
        </button> */}
      </div>
    </React.Fragment>
  );
};

export default AddOptionsDetail;
